import React from "react"
import { graphql, StaticQuery } from 'gatsby';
import styled from "styled-components"
import Layout from "../components/Layout"
import theme from "../general/theme"
import Box from "../elements/Box"
import OpenIcon from '-!svg-react-loader!../images/icons/open.svg'

import {
  Trafalgar,
  BodyCopy,
} from '../typography';
const { white, gray8 } = theme.colours;


export default props => (
  <StaticQuery
  query={graphql`
    query knowledgeTranslation {
      allSanityPdfs {
        edges {
          node {
            pdf_title
            Pdf {
              asset {
                url
              }
            }
          }
        }
      }
      allSanityDatcares {
        edges {
          node {
            datcare_title
            datcare {
              asset {
                url
              }
            }
          }
        }
      }
    }  
  `}
  render={data => <KnowledgeTranslation data={data} {...props} />}
  />
)

const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8rem;
`;


const KnowledgeTranslation = ({data}) => {
  const AllPdfs = data.allSanityPdfs.edges;
  const AllDatcares = data.allSanityDatcares.edges;

  
  function buildPdfCard(x) {
    return (
      <a href={x.node.Pdf.asset.url} target="_blank" rel="noopener noreferrer">
        <Box py="4rem" px="2rem" background={white} borderRadius="8px" mb="2rem" display="flex" justifyContent="space-between">
          <BodyCopy>{x.node.pdf_title}</BodyCopy>
          <OpenIcon></OpenIcon>
        </Box>
      </a>
    )
  }
  
  function buildDatcareCard(x) {
    return (
      <a href={x.node.datcare.asset.url} target="_blank" rel="noopener noreferrer">
        <Box py="4rem" px="2rem" background={white} borderRadius="8px" mb="2rem" display="flex" justifyContent="space-between">
          <BodyCopy>{x.node.datcare_title}</BodyCopy>
          <OpenIcon></OpenIcon>
        </Box>
      </a>
    )
  }
  
  return (
    <Layout>
      <Content>
        <>
          <Section>
            <>
              <Trafalgar mb="4rem" color={gray8}>Videos</Trafalgar>
              <a href="https://www.youtube.com/channel/UC5ymGo1jvYFygDj0lwcY2Zw/playlists" target="_blank" rel="noopener noreferrer">
                <Box py="4rem" px="2rem" background={white} borderRadius="8px" mb="2rem" display="flex" justifyContent="space-between">
                  <BodyCopy>WP2 produced videos about several topics. These can be viewed on our YouTube channel.</BodyCopy>
                  <OpenIcon></OpenIcon>
                </Box>
              </a>
            </>
          </Section>
          <Section>
            <Trafalgar mb="4rem" color={gray8}>Other knowledge translation products</Trafalgar>
            {AllPdfs.map(buildPdfCard)}
            {AllDatcares.map(buildDatcareCard)}
          </Section>
        </>
      </Content>
    </Layout>
  );
}

